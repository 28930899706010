<template>
  <component :is="componentType" class="heading" :class="{ '!justify-start': props.textAlign === 'left' }">
    <span class="heading__text" :class="[{ '!pl-4': props.textAlign === 'left' }, props.textClass]">
      <slot></slot>
    </span></component>
</template>
<script setup>

const props = defineProps({
  textClass: String,
  level: Number | undefined,
  textAlign: 'left' | undefined
})

const componentType = computed(() => {
  return props.level ? `h${props.level || 1}` : 'div'
})

</script>
<style lang="postcss">
.heading {
  @apply relative !flex items-center justify-center text-center !text-xl font-bold !tracking-tight !text-neutral-500;

  &:after {
    @apply content-[''] absolute left-0 right-0 !w-full !h-[1px] !bg-transparent bg-gradient-to-r from-transparent via-black to-transparent;
  }

  &__text {
    @apply relative z-[1] px-6 py-2 bg-neutral-50 desktop:px-16;
  }
}
</style>